  .chat {
    font-family: helvetica;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .messages {
    display: flex;
    flex-direction: column;
  }
  
  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  
  .yours {
    align-items: flex-start;
  }
  
  .yours .message {
    margin-right: 25%;
    background-color: #eee;
    position: relative;
  }
  
  .yours .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
  }
  .yours .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  }
  
  .mine {
    align-items: flex-end;
  }
  
  .mine .message {
    color: white;
    margin-left: 25%;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    position: relative;
  }
  
  .mine .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  
  .mine .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
  }
  